import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
	providedIn: 'root',
})
export class CookiesService {
	constructor(private cookieService: CookieService) {}

	setItem(key: string, value: string): void {
		const currentDomain = window.location.hostname;
		let domain = ''
		if (currentDomain.includes('localhost')) {
			domain = 'localhost'
		} else if(currentDomain.includes('sumax.pe')) {
			domain = '.sumax.pe'
		} else {
			domain = '.sintad.net.pe'
		}
		this.cookieService.set(key, value, undefined, '/', domain, true);
	}

	getItem(key: string): string {
		return this.cookieService.get(key);
	}

	removeItem(key: string): void {
		const currentDomain = window.location.hostname;
		let domain = ''
		if (currentDomain.includes('localhost')) {
			domain = 'localhost'
		} else if(currentDomain.includes('sumax.pe')) {
			domain = '.sumax.pe'
		} else {
			domain = '.sintad.net.pe'
		}
		this.cookieService.delete(key, '/', domain, true);
	}

	checkItem(key: string): boolean {
		return this.cookieService.check(key);
	}

	getBearerAuthorization(key: 'idToken' | 'maestroSyncToken'): string {
		const userToken = this.getItem(environment[key]);
		const bearer = 'Bearer ' + userToken;
		return bearer;
	}
}
